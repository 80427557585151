
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc, RelationshipsWithData, ResourceObject } from '@/models/jsonapi'
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { sortElements, sortData } from "@/utils/tableSort"

  export default defineComponent({
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = ref(0) //computed<number>(() => root.$store.state.seller_bills.pagination.totalItems)
      const tableOptions = {stacked: "md", perPage: 100}
      const getItems = async(ctx: BvTableCtxObject) => {
        const { sortKey, sortColumn, sortFunction } = sortElements(ctx, columns)
        const sellerBillsQueryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'include': 'file_meta_data',
          'fields[file_meta_datas]': 'all',
          'fields[seller_bills]': 'all',
          'sort': '-created_at',
          'relationships': "file_meta_data",
          // 'filter[file_meta_data.ne]': 'null'
        }
        /*if (sortKey) {
          sellerBillsQueryParams.sort = `${ctx.sortDesc ? '-' : ''}${ctx.sortBy?.replace('attributes.', '') ?? ''}`
        }*/
        await root.$store.dispatch('seller_bills/load2', sellerBillsQueryParams)
        const bills = root.$store.state.seller_bills.response.data
        const fmdIndex = root.$store.state.seller_bills.response.included.reduce((r,e) => {r[e.id]=e; return r}, {})
        const billsWithFile = bills.filter(bill => {
          if (bill.relationships?.fileMetaData?.data) {
            bill.attributes.file_meta_data = fmdIndex[bill.relationships.fileMetaData.data.id]
            return true
          }
        })
        // if (sortKey) {
        //   console.log({sortKey})
        //   const sortAttribute = ctx.sortBy?.replace('attributes.', '') ?? ''
        //   billsWithFile.sort(function(a, b) {
        //     const attributeA = a.attributes[sortAttribute]
        //     const attributeB = b.attributes[sortAttribute]
        //     if (attributeA < attributeB) {
        //       return ctx.sortDesc ? 1 : -1
        //     }
        //     if (attributeB < attributeA) {
        //       return ctx.sortDesc ? -1 : 1
        //     }
        //     return 0
        //   })
        // }
        totalItems.value = billsWithFile.length
        const billsWithFileSliced = billsWithFile.slice((ctx.currentPage - 1) * ctx.perPage, ctx.currentPage * ctx.perPage)
        return billsWithFileSliced
      }

      const columns = [
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.billNr",
          title: "BillNr",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.billDate",
          title: "Datum",
          sortable: true,
          options: {
            type: 'date'
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.paid",
          title: "Bezahlt",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
          key: "actions",
          title: "Actions",
          sortable: false,
          options: {
            buttons: [
              {
                title: 'View',
                icon: ['fas', 'eye'],
                action: (data: any) => root.$store.dispatch('file_meta_datas/display_file', { fmd: data.item.attributes.file_meta_data, shouldDownload: false })
              },
              {
                title: 'Download',
                icon: ['fas', 'download'],
                action: (data: any) => root.$store.dispatch('file_meta_datas/display_file', { fmd: data.item.attributes.file_meta_data, shouldDownload: true })
              },
            ]
          }
        }
      ]

      return {
        getItems,
        totalItems,
        columns,
        tableOptions
      }
    }
  })
